
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import API from "@/api/API";
import store from "@/store";
import { LocaleMessages } from "vue-i18n";

export default Vue.extend({
  name: "Create",

  data: () => ({
    rules,
    model: {} as any,
    roles: [] as Array<any>,
    users: [] as Array<any>,
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  computed: {
    minOneArray(): boolean | string | LocaleMessages {
      return (
        !!this.model.users?.length ||
        !!this.model.roles?.length ||
        this.$t("notifications.validation.select_receivers")
      );
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const roles = await API.roles().getList();
      const users = await API.users().getList();

      next(vm => {
        vm.roles = roles;
        vm.users = users;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as Vue).validate()) {
          await this.$API.notifications().create({ ...this.model });
          await this.$router.push("/notifications");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
